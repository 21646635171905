import React from "react"

const BarryOglesbee = (
  <>
  <h4>Electricians Mate Nuclear First Class (EM1) US Navy Veteran</h4>
    <p>
    Barry info coming soon.
    </p>
  </>
)

export default BarryOglesbee
